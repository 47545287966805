<template>
    <div class="retrieve">
        <div class="content">
            <div class="title">
                <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
                <router-link to="/">返回首页</router-link>
            </div>
            <div class="tab">
                <h1>通过以下流程找回密码</h1>
                <ul class="ul">
                    <li class="active">
                        <img src="~/static/login/01_active.png" alt="">
                        <h3>通过以下流程找回密码</h3>
                    </li>
                    <li>
                        <img src="~/static/login/02_active.png" alt="">
                        <h3>验证信息</h3>
                    </li>
                    <li>
                        <img src="~/static/login/03.png" alt="">
                        <h3>重置密码</h3>
                    </li>
                </ul>
            </div>
            <div class="form">
                <el-radio-group v-model="checkbox" @change="handChange" class="radio">
                    <el-radio label="1">
                        <div class="info">
                            <span class="h3">通过手机号码找回密码</span>
                            <p>
                                通过已经注册的手机号码获取电信验证码方式找回密码
                            </p>
                        </div>
                    </el-radio>
                    <el-radio label="2">
                        <div class="info">
                            <span class="h3">通过绑定邮箱找回密码</span>
                            <p>通过已经绑定的邮箱获取邮箱验证码方式找回密码</p>
                        </div>
                    </el-radio>
                    <el-radio label="3">
                        <div class="info">
                                <span class="h3">
                                通过预留信息申诉找回密码
                            </span>
                            <p>如以上两种方式都无法找回，请通过申诉找回</p>
                        </div>
                    </el-radio>
                </el-radio-group>
                <div class="btn">
                    <el-button type="primary" class="w336" @click="goRouter">下一步</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                checkbox: '1',
                url:'/retrievePassword/phone',
            }
        },
        methods: {
            handChange(val) {
                if (val == 1){
                    this.url = '/retrievePassword/phone';
                }else if (val == 2){
                    this.url = '/retrievePassword/email';
                }else{
                    this.url = '/retrievePassword/info';
                }
            },
            goRouter(){
                this.$router.push(this.url);
            }
        }
    }
</script>

<style scoped lang="less">
    .retrieve {
        width: 100%;
        min-height: 100vh;
        background: white;

        .w336 {
            width: 336px;
        }

        .content {
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 100px;

            .title {
                display: flex;
                padding-top: 26px;

                h3 {
                    flex: 1;
                    font-size: 18px;
                    color: #28A46D;
                }

                a {
                    font-size: 18px;
                    color: #66716C;
                }

                a:hover {
                    color: #28A46D;
                }
            }

            .tab {
                width: 635px;
                margin: 0 auto;
                padding-top: 60px;

                h1 {
                    font-size: 28px;
                    padding-bottom: 36px;
                    text-align: center;
                }

                .ul {
                    display: flex;

                    li {
                        position: relative;
                        flex: 1;
                        text-align: center;

                        h3 {
                            font-size: 18px;
                            margin-top: 6px;
                            color: #66716c;
                        }
                    }

                    li.active h3 {
                        color: #28A46D;
                    }

                    li::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: -50px;
                        transform: translateY(-50%);
                        width: 102px;
                        height: 2px;
                        background: #CCD0CE;
                    }

                    li:last-child::after {
                        display: none;
                    }
                }
            }
        }

        .form {
            width: 610px;
            margin: 48px auto 0 auto;

            /deep/ .el-radio-group {
                .el-radio {
                    display: flex;
                    margin-bottom: 48px;

                    .el-radio__input {
                        .el-radio__inner {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    .info {
                        .h3 {
                            font-size: 18px;
                            color: #00120A;
                        }

                        p {
                            font-size: 16px;
                            color: #66716C;
                            margin-top: 6px;
                        }
                    }
                }
            }

            .btn {
                text-align: center;
                margin-top: 46px;
            }
        }
    }
</style>
